/* Default styles for mobile */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: Arial, sans-serif;
  font-size: 1.4rem;
}
img {
  width: 100%;
}
p {
  color: #333;
  line-height: 1.5;
  margin-bottom: 1.5rem;
}
/* mobile styles */
.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 0px;
}
.contactlabel {
  font-size: 20px;
  margin-left: -320px;
  margin-bottom: 10px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 20px 0; */
  position: fixed;
  top: 0;
  padding-top: 1%;
  width: 100%;
  background-color: white;
  z-index: 1000;
}
h2 {
  text-align: center;
}
.banner {
  margin-bottom: 5%;
}
.logo {
  font-size: 24px;
  font-weight: bold;
  margin-left: 10%;
  cursor: pointer;
}

.navbar {
  display: flex;
  align-items: center;
  margin-right: 10%;
}

.navbar a {
  text-decoration: none;
  color: #7265e3;
  margin-right: 20px;
  margin-left: 20px;
}

.navbar-toggle {
  display: none;
  cursor: pointer;
  color: #ff934e;
  margin-right: 50px;
}
.banner-large {
  display: none;
}
.navbar {
  display: none;
}
.banner-button {
  background-color: #ff934e;
  color: white;
  border-radius: 50px;
  padding: 20px;
  border: 0px;
  width: 250px;
  cursor: pointer;
  position: absolute;
  bottom: 13%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.bannerParagraph {
  display: none;
}
h3 {
  text-align: center;
  margin-bottom: -10px;
  margin-left: 2%;
  margin-right: 2%
}

p {
  margin-left: 2%;
  margin-right: 2%;

}

.navbar.active {
  display: flex;
  flex-direction: column;
  background-color: #f9f9f9;
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  padding: 10px 0;
  z-index: 1;
}

.navbar.active a {
  margin: 10px 0;
}

.navbar-toggle {
  display: block;
  z-index: 1;

}

.banner img.banner-mobile {
  display: block;
  width: 100%;
  margin-top: 10%;
}

.featureImages {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.featureImages img {
  cursor: pointer;
  width: 100%;
  max-width: 200px;
  margin-bottom: 20px;
}

.team-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: -350px;
}

.team-wrapper .team {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
}

.team-wrapper .team img {
  width: 100%;
  max-width: 250px;
  margin-bottom: 20px;
  cursor: pointer;
}
.goallarge {
  display: block;
  margin: 0 auto;
  max-width: 80%;
}

.goal {
  margin-top: 10px;
  margin-bottom: 0px;
  cursor: pointer;
}

.banner2 {
  margin-bottom: 10px;
  margin-top: 20px;
}

.contact {
  text-align: center;
  position: relative;
  margin-top: -200px;
}
.contact img.contactMobile {
  display: block;
  width: 100%;
  margin-top: 20px;
}
.banner2large2 {
  display: none;
}
.banner2mobile {
  width: 100%;
}
.contact img.contactLarge {
  display: none;
}
.contact-form {
  width: 90%;
  max-width: 500px;
  height: 200px;
  margin: 0 auto;
  position: absolute;
  bottom: 320px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.contact-form input[type="text"],
.contact-form input[type="email"],
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #7265e3;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.contact-form textarea {
  height: 100px;
}
.contact-form button {
  width: 30%;
  padding: 5px;
  border: none;
  border-radius: 50px;
  background-color: #7265e3;
  color: white;
  cursor: pointer;
}
.contactlabel {
  font-size: 20px;
  margin-left: -0;
}

h2,
p {
  text-align: center;
  margin-bottom: 20px;
}
/* Larger phones */
@media (min-width: 375px) {
}
/* Tables */
@media (min-width: 768px) {
  p {
    margin-left: 50px;
  }
}
/* Small  Desktops */
@media (min-width: 1280px) {

  .navbar-toggle {
    display: none;
  }
  .navbar {
    display: flex !important;
  }
  .navbar .talk {
    border: solid;
    background-color: #7265e3;
    border-radius: 20px;
    padding: 15px;
    color: white;
  }
  .banner img.banner-mobile {
    display: none;
  }
  .banner img.banner-large {
    display: block;
    width: 100%;
    max-width: 100vw;
    margin: 0 auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  .banner2 img.banner2large2 {
    display: block;
    width: 100%;
    max-width: 100vw;
    margin: 0 auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  .bpMobile {
    display: none;
  }

  .banner-button {
    background-color: #ff934e;
    color: white;
    border-radius: 50px;
    padding: 20px;
    border: 0px;
    width: 200px;
    cursor: pointer;
    position: absolute;
    bottom: 350px;
    left: 18%;
    transform: translateX(-50%);
    z-index: 1;
  }

  .bannerParagraph {
    display: block;
    position: absolute;
    top: 38%;
    left: 8%;
    transform: translateY(-50%);
    color: black;
    font-size: 20px;
    text-align: left;
  }
  .feature h3 {
    text-align: left;
    /* margin-bottom: -100px; */
  }
  .feature {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10%;
  }
  .featureImages {
    display: flex;
    flex-direction: row;
    justify-content: center;

    align-items: center;
    padding: 20px;
  }

  .featureImages img {
    cursor: pointer;
    width: 100%;
    max-width: 200px;
    margin: 20px;
  }
  .goal h2,
  p {
    text-align: left;
    margin-left: 10%;
    margin-bottom: 20px;
  }
  .banner2 .banner2mobile {
    display: none;
    margin-bottom: 20px;
  }
  .team-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-bottom: 150px;
    margin-right: 50px;
  }

  .team-wrapper .team {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .team-wrapper .team img {
    width: 100%;
    max-width: 150px;
    margin-bottom: 20px;
    margin-right: 20px;
    cursor: pointer;
    padding-right: 50px;
  }

  .designerteam {
    order: 0;
  }
  h2,
  p {
    text-align: left;
    margin-top: 10px;
    margin-left: 10%;
  }

  .contact {
    text-align: center;
    position: relative;
    margin-top: -200px;
  }

  .contact img.contactLarge {
    display: block;
    width: 100%;
    margin-top: 200px;
    cursor: pointer;
  }

  .contact img.contactMobile {
    display: none;
  }
  .contact-form {
    width: 100%;
    max-width: 600px;
    height: 350px;
    margin: 0 auto;
    position: absolute;
    bottom: 250px;
    left: 65%;
    transform: translateX(-50%);
    z-index: 1;
  }
  .contactlabel {
    margin-left: -500px;
  }

  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #7265e3;
    box-sizing: border-box;
    margin-bottom: 10px;
  }

  .contact-form textarea {
    height: 100px;
  }

  .contact-form button {
    width: 30%;
    padding: 15px;
    border: none;
    border-radius: 50px;
    background-color: #7265e3;
    color: white;
    cursor: pointer;
  }
}
/* Large Desktops */
@media (min-width: 1440px) {
  .navbar-toggle {
    display: none;
  }
  .navbar {
    display: flex !important;
  }
  .navbar .talk {
    border: solid;
    background-color: #7265e3;
    border-radius: 20px;
    padding: 15px;
    color: white;
  }
  .banner img.banner-mobile {
    display: none;
  }
  .banner img.banner-large {
    display: block;
    width: 100%;
    max-width: 100vw;
    margin: 0 auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }

  .banner2 img.banner2large2 {
    display: block;
    width: 100%;
    max-width: 100vw;
    margin: 0 auto;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  .bpMobile {
    display: none;
  }

  .banner-button {
    background-color: #ff934e;
    color: white;
    border-radius: 50px;
    padding: 20px;
    border: 0px;
    width: 200px;
    cursor: pointer;
    position: absolute;
    bottom: 250px;
    left: 18%;
    transform: translateX(-50%);
    z-index: 1;
  }

  .bannerParagraph {
    display: block;
    position: absolute;
    top: 43%;
    left: 8%;
    transform: translateY(-50%);
    color: black;
    font-size: 20px;
    text-align: left;
  }
  .feature h3 {
    text-align: left;
    /* margin-bottom: -100px; */
  }
  .feature {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10%;
  }
  .featureImages {
    display: flex;
    flex-direction: row;
    justify-content: center;

    align-items: center;
    padding: 20px;
  }

  .featureImages img {
    cursor: pointer;
    width: 100%;
    max-width: 200px;
    margin: 20px;
  }
  .goal h2,
  p {
    text-align: left;
    margin-left: 10%;
    margin-bottom: 20px;
  }
  .banner2 .banner2mobile {
    display: none;
    margin-bottom: 20px;
  }

  .team-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-bottom: 150px;
    margin-right: 50px;
  }

  .team-wrapper .team {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .team-wrapper .team img {
    width: 100%;
    max-width: 150px;
    margin-bottom: 20px;
    margin-right: 20px;
    cursor: pointer;
    padding-right: 50px;
  }

  .designerteam {
    order: 0;
  }
  h2,
  p {
    text-align: left;
    margin-top: 30px;
    margin-left: 12%;
  }

  .contact {
    text-align: left;
    position: relative;
  }

  .contact img.contactLarge {
    display: block;
    width: 100%;
    margin-top: 200px;
    cursor: pointer;
  }

  .contact img.contactMobile {
    display: none;
  }
  .contact-form {
    width: 100%;
    max-width: 600px;
    height: 350px;
    margin: 0 auto;
    position: absolute;
    bottom: 300px;
    left: 65%;
    transform: translateX(-50%);
    z-index: 1;
  }
  .contactlabel {
    margin-left: 0%;
  }

  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form textarea {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #7265e3;
    box-sizing: border-box;
    margin-bottom: 10px;
  }

  .contact-form textarea {
    height: 100px;
  }

  .contact-form button {
    width: 30%;
    padding: 15px;
    border: none;
    border-radius: 50px;
    background-color: #7265e3;
    color: white;
    cursor: pointer;
  }
}
